<template>
	<div :id="id" class="chartbox"></div>
</template>

<script>
	export default {
		name: "",
		components: {},
		props: {
			id: {
				type: String,
				default: ""
			},
			chartDatas: {
				type: Object,
				default: () => {

				}
			}
		},
		data() {
			return {}
		},
		watch: {
			chartDatas() {
				// console.log(this.chartDatas);
				this.getChart();
			},
		},
		created() {},
		mounted() {},
		computed: {},
		methods: {
			//吸毒人员地区分布
			getChart() {
				let chart = this.$echarts.init(document.getElementById(`${this.id}`));
				chart.setOption({
					color: ['#f25936'],
					grid: {
						left: '0',
						right: '0',
						bottom: '0',
						top: '10%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						data: this.chartDatas.area,
						axisTick: {
							show: false
						},
						axisLine: {
							lineStyle: {
								color: '#8a94b4'
							}
						}
					},
					yAxis: {
						type: 'value',
						axisLine: {
							lineStyle: {
								color: '#8a94b4'
							}
						},
						splitLine: {
							lineStyle: {
								color: '#29406a'
							}
						}
					},
					series: [{
						data: this.chartDatas.data,
						type: 'line',
						smooth: true
					}]
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.chartbox {
		width: 100%;
		height: 100%;
	}
</style>
