<template>
  <div :id="id" class="chartbox"></div>
</template>

<script>
export default {
  name:"",
  components:{},
  props:{
    id:{
      type: String,
      default: ""
    },
    chartDatas:{
      type: Object,
      default: ()=>{

      }
    }
  },
  data(){
    return {
    }
  },
  watch:{
    chartDatas(){
      this.getChart();
    },
  },
  created(){},
  mounted(){},
  computed:{},
  methods:{
    //吸毒毒品类别分类
    getChart() {
      let chart = this.$echarts.init(document.getElementById(`${this.id}`));
      chart.setOption({
        color: this.chartDatas.colors,
        legend: {
            top: 'bottom',
            textStyle: {
              color: "#fff",
            },
            icon: 'circle'
        },
        tooltip: {
          trigger: 'item',
          // formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
          {
            name: '各社区用户占比',
            type: 'pie',
            radius: [70, 95],
            center: ['40%', '40%'],
            itemStyle: {
                borderRadius: 0
            },
            data: this.chartDatas.data,
            label:{
              formatter: '{b}\n {d}%',
              lineHeight: 18,
              fontSize: 12,
              show: false
            },
          }
        ]
    },true);
    },
  }
}
</script>

<style lang="scss" scoped>
  .chartbox{
    width: 100%;
    height: 100%;
  }
</style>