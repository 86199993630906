import request from '@/utils/request'

//-------------大屏---------

//用户情况
export function userStatistics(data) {
  return request({
    url: '/MA_dp/userStatistics',
    method: 'post',
    params: data
  })
}


//活动总体情况
export function activityStatistics(data) {
  return request({
    url: '/MA_dp/activityStatistics',
    method: 'post',
    params: data
  })
}


//社区活动参与人数排名
export function activityJoinRanking(data) {
  return request({
    url: '/MA_dp/activityJoinRanking',
    method: 'post',
    params: data
  })
}


//积分情况
export function pointStatistics(data) {
  return request({
    url: '/MA_dp/pointStatistics',
    method: 'post',
    params: data
  })
}