<template>
  <div :id="id" class="chartbox"></div>
</template>

<script>
export default {
  name:"",
  components:{},
  props:{
    id:{
      type: String,
      default: ""
    },
    chartDatas:{
      type: Object,
      default: ()=>{

      }
    }
  },
  data(){
    return {
    }
  },
  watch:{
    chartDatas(){
      this.getChart();
    },
  },
  created(){},
  mounted(){},
  computed:{},
  methods:{
    //吸毒毒品类别分类
    getChart() {
      let chart = this.$echarts.init(document.getElementById(`${this.id}`));
      chart.setOption({
        color: this.chartDatas.colors,
        legend: {
            top: 'bottom',
            left: 'left',
            textStyle: {
              color: "#fff",
            },
            icon: 'circle'
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '各类活动占比',
            type: 'pie',
            radius: [20, 95],
            center: ['50%', '45%'],
            roseType: 'area',
            itemStyle: {
                borderRadius: 0
            },
            emphasis: {
              label: {
                show: true,
                color: '#fff',
                textShadow: 'none'
              },
              labelLine: {
                show: true,
                lineStyle: {
                  color: '#fff'
                }
              }
            },
            data: this.chartDatas.data,
            label:{
              formatter: '{b}\n{d}%',
              lineHeight: 18,
              fontSize: 12,
              show: false
            },
          }
        ]
    });
    },
  }
}
</script>

<style lang="scss" scoped>
  .chartbox{
    width: 100%;
    height: 100%;
  }
</style>