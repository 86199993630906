import { render, staticRenderFns } from "./pointsoutOfnumber.vue?vue&type=template&id=5a3986a8&scoped=true&"
import script from "./pointsoutOfnumber.vue?vue&type=script&lang=js&"
export * from "./pointsoutOfnumber.vue?vue&type=script&lang=js&"
import style0 from "./pointsoutOfnumber.vue?vue&type=style&index=0&id=5a3986a8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a3986a8",
  null
  
)

export default component.exports