<template>
  <div :id="id" class="chartbox"></div>
</template>

<script>
export default {
  name:"",
  components:{},
  props:{
    id:{
      type: String,
      default: ""
    },
    chartDatas:{
      type: Object,
      default: ()=>{

      }
    }
  },
  data(){
    return {
    }
  },
  watch:{
    chartDatas(){
      this.getChart();
    },
  },
  created(){},
  mounted(){},
  computed:{},
  methods:{
    //吸毒毒品类别分类
    getChart() {
      let chart = this.$echarts.init(document.getElementById(`${this.id}`));
      chart.setOption({
        color: this.chartDatas.colors,
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            name: '兑换类别占比',
            type: 'pie',
            radius: [40, 60],
            center: ['50%', '50%'],
            itemStyle: {
                borderRadius: 0
            },
            data: this.chartDatas.data,
            label:{
              fontSize: 12,
              color: '#8a94b4',
            },
            labelLine: {
              // show:　false
              length: 0,
              length2: 0
            }
          }
        ]
    });
    },
  }
}
</script>

<style lang="scss" scoped>
  .chartbox{
    width: 100%;
    height: 100%;
  }
</style>