<template>
  <div :id="id" class="chartbox"></div>
</template>

<script>
export default {
  name:"",
  components:{},
  props:{
    id:{
      type: String,
      default: ""
    },
    chartDatas:{
      type: Object,
      default: ()=>{

      }
    }
  },
  data(){
    return {
    }
  },
  watch:{
    chartDatas(){
      this.getChart();
    },
  },
  created(){},
  mounted(){},
  computed:{},
  methods:{
    //用户兑换次数排名 TOP6
    getChart() {
      let chart = this.$echarts.init(document.getElementById(`${this.id}`));
      chart.setOption({
        color: ['#5c7ceb'],
         grid: {
          left: '0',
          right: '0',
          bottom: '0',
          top: '15%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.chartDatas.area,
          axisTick: {
            show: false
          },
          axisLine:{
            lineStyle: {
              color: '#8a94b4'
            }
          }
        },
        yAxis: {
          name: '次',
          type: 'value',
          axisLine:{
            lineStyle: {
              color: '#8a94b4'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#29406a'
            }
          }
        },
        series: [
          {
            data: this.chartDatas.data,
            type: 'bar',
            smooth: true,
            barWidth: 20
          }
        ]
      });
    },
  }
}
</script>

<style lang="scss" scoped>
  .chartbox{
    width: 100%;
    height: 100%;
  }
</style>