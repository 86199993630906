<template>
  <div :id="id" class="chartbox"></div>
</template>

<script>
export default {
  name:"",
  components:{},
  props:{
    id:{
      type: String,
      default: ""
    },
    chartDatas:{
      type: Object,
      default: ()=>{

      }
    }
  },
  data(){
    return {
    }
  },
  watch:{
    chartDatas(){
      this.getChart();
    },
  },
  created(){},
  mounted(){},
  computed:{},
  methods:{
    //吸毒人员地区分布
    getChart() {
      let chart = this.$echarts.init(document.getElementById(`${this.id}`));
      chart.setOption({
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'line'
            }
        },
        grid: {
            left: '5%',
            right: '8%',
            bottom: '2%',
            top: '10',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            splitLine:{
                show: false
            },
            axisLine: {
              show: false
            },
            axisLabel:{
              show: false
            },
            axisTick:{
                show: false
            },
        },
        yAxis: {
            type: 'category',
            // data: ['平定县', '孟县', '城区', '矿区', '郊区'],
            data: this.chartDatas.area,
            axisTick:{
                show: false
            },
            axisLine:{
                show: false,
                lineStyle: {
                  color: "#3490d0"
                }
            },
            axisLabel:{
              show: true,
              color: '#3bccf7',
              fontSize: 14,
              padding: [0,10,0,0]
            },
            inverse: true
        },
        series: [
            {
                name: '',
                type: 'bar',
                // data: [182, 234, 29, 104, 131],
                data: this.chartDatas.data,
                barWidth: '40%',
                itemStyle:{
                  color:'#00b7ee',
                  // barBorderRadius:[15, 15, 15, 15]
                },
                label: {
                    show: true,
                    formatter: '{c} 人',
                    color: '#fff',
                    position: 'right'
                },
                showBackground: true,
                backgroundStyle: {
                    color: '#435583',
                    // borderRadius: 15
                }
            }
        ]
      });
    },
  }
}
</script>

<style lang="scss" scoped>
  .chartbox{
    width: 100%;
    height: 100%;
  }
</style>