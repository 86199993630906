<template>
	<div class="monitorbox">
		<div class="top_title">
			<el-button type="primary" @click="refresh" round>刷新数据</el-button>
			<img src="../../assets/imgs/monitor/monitor_tit.jpg" alt="大屏">
			<el-button type="primary" @click="gotoIndex" round>返回管理平台</el-button>
		</div>
		<div class="bot_content">
			<div class="content">
				<div class="cont_side">
					<div class="cont_side_top boxshadow">
						<div class="tit">
							<i class="iconfont icon-sanjiaoright"></i>用户情况
						</div>
						<div class="cont">
							<div class="community">
								<ul>
									<li>
										<div class="blue">社区数</div>
										<span><em>{{userStatisticsDetail.sheQuShu}}</em>人</span>
									</li>
									<li>
										<div class="yellow">用户数</div>
										<span><em>{{userStatisticsDetail.yongHuShu}}</em>人</span>
									</li>
									<li>
										<div class="lightblue">实名数</div>
										<span><em>{{userStatisticsDetail.shiMingShu}}</em>人</span>
									</li>
									<li>
										<div class="puple">本月新增用户数</div>
										<span><em>{{userStatisticsDetail.xinYongHuShu}}</em>人</span>
									</li>
								</ul>
							</div>
							<div class="echart">
								<div class="name">各社区用户占比</div>
								<div style="height: calc(100% - 20px);">
									<community-users id="rightcent" :chartDatas="userStatisticsDate"></community-users>
								</div>
							</div>
						</div>
					</div>
					<div class="cont_side_bot boxshadow">
						<div class="tit">
							<i class="iconfont icon-sanjiaoright"></i>活动总体情况
						</div>
						<div class="cont">
							<div class="activitytype">
								<div>活动总数<span><em>{{hdZongZhu}}</em>场</span></div>
								<ul>
									<li v-for="(item,index) in activityTypeData" :key="index">
										<div v-if="item.name == '普通活动'"><i class="iconfont icon-liwu"></i>普通活动</div>
										<div v-if="item.name == '预约活动'"><i class="iconfont icon-yuyue"></i>预约活动</div>
										<div v-if="item.name == '公告活动'"><i class="iconfont icon-navicon-gggl"></i>公告活动</div>
										<span><em>{{item.value}}</em>场</span>
									</li>
								</ul>
							</div>
							<div class="echart">
								<div class="name">各类活动占比</div>
								<div style="height: calc(100% - 30px);">
									<activity-type id="leftbot" :chartDatas="activityTypeChartData"></activity-type>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="cont_cent boxshadow">
					<div class="cont_cent_top">
						<div class="tit">
							<i class="iconfont icon-sanjiaoright"></i>最近活动情况
						</div>
						<div class="echart">
							<div class="gifpart">
								<img src="../../assets/imgs/monitor/monitor_map.gif" alt="">
								<div @mouseover="setZoom('zIndex1')"
									:style="{zIndex: zIndex1}" class="one">实名用户<span class="smlyellow"><em
											class="animate__animated animate__fadeInLeft">50</em>人</span></div>
								<div @mouseover="setZoom('zIndex2')"
									:style="{zIndex: zIndex2}" class="two">未实名用户<span class="smlyellow"><em
											class="animate__animated animate__fadeInLeft">20</em>人</span></div>
								<div @mouseover="setZoom('zIndex3')"
									:style="{zIndex: zIndex3}" class="three">新用户注册数<span class="bigyellow"><em
											class="animate__animated animate__fadeInLeft">32</em>人</span></div>
								<div @mouseover="setZoom('zIndex4')"
									:style="{zIndex: zIndex4}" class="four">实际报名人数<span class="bigblue"><em
											class="animate__animated animate__fadeInLeft">32</em>人</span></div>
								<div @mouseover="setZoom('zIndex5')"
									:style="{zIndex: zIndex5}" class="five">
									<div>进行中活动数 <span class="smlblue"><em
												class="animate__animated animate__fadeInLeft">5</em>人</span></div>
									<div>未开始活动数 <span class="smlblue"><em
												class="animate__animated animate__fadeInLeft">4</em>个</span></div>
								</div>
								<div @mouseover="setZoom('zIndex6')"
									:style="{zIndex: zIndex6}" class="six">实际浏览人数<span class="bigpink"><em
											class="animate__animated animate__fadeInLeft">32</em>人</span></div>
							</div>
						</div>
					</div>
					<div class="cont_cent_bot">
						<div class="toprank">
							社区活动参与人数排名 TOP
						</div>
						<div class="echart">
							<activity-joined id="centbot" :chartDatas="activityJoinedData"></activity-joined>
						</div>
					</div>
				</div>
				<div class="cont_side">
					<div class="cont_side_top boxshadow">
						<div class="tit">
							<i class="iconfont icon-sanjiaoright"></i>积分情况
						</div>
						<div class="coincont">
							<div class="coin">
								<div class="coinleft">
									<div>
										<span>{{jifen.jfZongShu}}</span>积分总数
									</div>
								</div>
								<div class="line"></div>
								<div class="coincent">
									<!-- <div>签到积分总数<span>421</span></div>
									<div>发放积分总数<span>{{jifen.jfFaFangShu}}</span></div> -->

									<div>活动发放积分数<span>{{jifen.jfHuoDong}}</span></div>
									<div>任务发放积分数<span>{{jifen.jfRengWu}}</span></div>
									<div>其他发放积分数<span>{{jifen.jfQiTa}}</span></div>
									
								</div>
								<div class="line"></div>
								<div class="coinright">
									<div>发放积分完成率</div>
									<div class="chart">
										<el-progress :width="96" :stroke-width="8" color="#f00" type="circle"
											:percentage="jifen.jfwanchenglv"></el-progress>
									</div>
								</div>
							</div>
							<div class="coindispense">
								<div class="text">各社区积分发放数</div>
								<div class="chart">
									<pointsout-ofnumber id="pointsout" :chartDatas="jifen.sqZhanBi">
									</pointsout-ofnumber>
								</div>
							</div>
						</div>
					</div>
					<div class="cont_side_bot boxshadow">
						<div class="tit">
							<i class="iconfont icon-sanjiaoright"></i>积分兑换情况
						</div>
						<div class="exchangecont">
							<div class="exchange_top">
								<ul>
									<li>
										<div>可兑换服务/产品数</div>
										<div style="color: #ffac36;"><em>125</em>个</div>
									</li>
									<li>
										<div>已兑换次数<span>34</span>次</div>
										<div>已消耗积分<span>326</span></div>
									</li>
									<li>
										<div>本月总的次数<span>34</span>次</div>
										<div>本月消耗积分<number ref="number1" :from="9000" :to="10000" :format="theFormat" :duration="0.5" :delay="0.3" easing="Power1.easeOut"/></div>
									</li>
								</ul>
							</div>
							<div class="exchange_bot">
								<div class="exchange_bot_left">
									<div class="text">兑换类别占比</div>
									<div class="chart">
										<pointsout-type id="pointsoutType" :chartDatas="pointsoutTypeData">
										</pointsout-type>
									</div>
								</div>
								<div class="exchange_bot_right">
									<div class="text">用户兑换次数排名 TOP6</div>
									<div class="chart">
										<pointsout-users id="pointsoutUser" :chartDatas="pointsoutUsersData">
										</pointsout-users>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		userStatistics,
		pointStatistics,
		activityJoinRanking,
		activityStatistics
	} from '@/api/monitor.js'
	import communityUsers from './communityUsers.vue'
	import activityType from './activityType.vue'
	import activityJoined from './activityJoined.vue'
	import pointsoutOfnumber from './pointsoutOfnumber.vue'
	import pointsoutType from './pointsoutType.vue'
	import pointsoutUsers from './pointsoutUsers.vue'

	let colors = ['#3bccf7', '#0087ff', '#2dff9e', '#fec14d', '#f95537', '#ffc520', '#08cf99', '#00d1fe', '#58bf39',
		'#777aff', '#ca43c8', '#777aff'
	];
	export default {
		name: "",
		components: {
			communityUsers,
			activityType,
			activityJoined,
			pointsoutOfnumber,
			pointsoutType,
			pointsoutUsers
		},
		props: {},
		data() {
			return {
				jifen:{
					jfZongShu:0,
					jfwanchenglv:0,
					jfFaFangShu:0,
					jfHuoDong:0,
					jfRengWu:0,
					jfQiTa:0,
					sqZhanBi:{
						area:[],
						data:[]
					}
				},
				hdZongZhu:'0',
				userStatisticsDate: {
					colors: [],
					data: []
				},
				userStatisticsDetail: {
					sheQuShu: 0,
					shiMingShu: 0,
					xinYongHuShu: 0,
					yongHuShu: 0
				},
				activityTypeData: [],
				activityTypeChartData: {
					colors: [],
					data: []
				},
				activityJoinedData: {
					area: [],
					data: []
				},
				pointsoutOfnumberData: {
					type: [],
					data: []
				},
				pointsoutTypeData: {
					type: [],
					data: []
				},
				pointsoutUsersData: {
					colors: [],
					data: []
				},
				zIndex1: 10,
				zIndex2: 10,
				zIndex3: 10,
				zIndex4: 10,
				zIndex5: 10,
				zIndex6: 10,
				zIndex7: 10,
			}
		},
		watch: {},
		created() {},
		mounted() {
			this.getUserStatistics();
			this.getActivityStatistics();
			this.getActivityJoinRanking();
			this.getPointStatistics();
			
			this.pointsoutTypeData = {
				colors: ['#5470c6', '#91cc75', '#fac858'],
				data: [{
					name: "普通活动",
					value: 117
				}, {
					name: "预约活动",
					value: 132
				}, {
					name: "公告活动",
					value: 54
				}]
			}
			this.pointsoutUsersData = {
				area: ["活动1", "活动2", "活动3", "活动4", "活动5", "活动6", "活动7"],
				data: [90, 332, 201, 934, 190, 330, 320]
			}
		},
		computed: {},
		methods: {
			theFormat(number) {
				return number.toFixed(0);
			},
			//返回管理平台
			gotoIndex() {
				this.$router.push('/')
			},
			//刷新页面
			refresh() {
				this.getUserStatistics();
				this.getActivityStatistics();
				this.getActivityJoinRanking();
				this.getPointStatistics();
			},
			//获取用户信息
			getUserStatistics() {

				userStatistics().then(res => {
					if (res.result == 200) {
						let resultData = res.detail.yongHuZhanBi;
						resultData.forEach((item, index) => {
							item.label = {
									color: colors[index]
								},
								item.name = item.communityname,
								item.value = item.usercount
						})
						this.userStatisticsDate = {
							colors: colors,
							data: resultData
						}
						let resData = res.detail;
						this.userStatisticsDetail = {
							sheQuShu: resData.sheQuShu,
							shiMingShu: resData.shiMingShu,
							xinYongHuShu: resData.xinYongHuShu,
							yongHuShu: resData.yongHuShu,
						}
					} else {
						this.$message.error(res.description)
					}
				})
			},
			//活动总体情况
			getActivityStatistics() {
				activityStatistics().then(res => {
					if (res.result == 200) {
						this.hdZongZhu = res.detail.hdZongZhu;
						let hdZhanBi = res.detail.hdZhanBi;
						hdZhanBi.map(item=>{
							item.name = item.acttypename,
							item.value = item.activitycount
						})
						this.activityTypeData = hdZhanBi;
						this.activityTypeChartData = {
							colors: colors,
							data: hdZhanBi
						}
					} else {
						this.$message.error(res.description)
					}
				})
			},
			//积分情况
			getPointStatistics(){
				pointStatistics().then(res => {
					if (res.result == 200) {
						let detail = res.detail;
						this.jifen = {
							jfFaFangShu: detail.jfFaFangShu,
							jfZongShu: detail.jfZongShu,
							jfwanchenglv: Number(detail.jfwanchenglv),
							jfHuoDong: detail.jfHuoDong,
							jfRengWu: detail.jfRengWu,
							jfQiTa: detail.jfQiTa,
						}
						let shuju = {
							area:[],
							data:[]
						}
						for(let item of detail.sqZhanBi){
							shuju.area.push(item.communityname);
							shuju.data.push(item.pointnum);
						}
						this.jifen.sqZhanBi = shuju;
						// console.log(this.jifen.sqZhanBi);
					} else {
						this.$message.error(res.description)
					}
				})
			},
			//社区活动参与人数排名 TOP
			getActivityJoinRanking(){
				activityJoinRanking().then(res => {
					if (res.result == 200) {
						console.log(res);
						let ranking = res.detail.ranking;
						let rankingData = {
							area:[],
							data:[]
						};
						for(let item of ranking){
							rankingData.area.push(item.name);
							rankingData.data.push(item.joinnum);
						}
						this.activityJoinedData = rankingData;
					} else {
						this.$message.error(res.description)
					}
				})
			},
			//设置大的zindex
			setZoom(zoom) {
				for (let i = 1; i <= 6; i++) {
					this[`zIndex${i}`] = 10;
				}
				this[zoom] = 100;
			},
		}
	}
</script>

<style lang="scss" scoped>
	.monitorbox {
		background: #06142f;
		width: 2600px;
		height: 1125px;
		// width: 1980px;
		// height: 1020px;
		overflow: hidden;
	}

	.top_title {
		width: 100%;
		height: 9%;
		background: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		background-image: url('../../assets/imgs/monitor/monitor_tit_bg.jpg');
		background-position: center;
		background-size: auto 100%;

		img {
			height: 100%;
			width: auto;
		}
	}

	.bot_content {
		height: 91%;
		padding-top: 10px;
		box-sizing: border-box;
		display: flex;
		justify-content: center;

		.content {
			height: 89%;
			width: 96%;
			display: flex;
			justify-content: space-between;
			color: #fff;

			.boxshadow {
				border: 1px solid #153560;
				box-sizing: border-box;
				box-shadow: 5px 5px 30px 10px rgba(21, 53, 96, 0.4) inset;
				border-radius: 4px;

				.tit {
					height: 44px;
					background-image: linear-gradient(to bottom, rgba(19, 44, 84, 0.8), rgba(8, 22, 47, 0.4));
					line-height: 44px;
					color: #fff;
					font-size: 20px;
					padding: 0 20px;

					.iconfont {
						color: #01bcba;
						font-size: 22px;
						margin-right: 10px;
					}
				}
			}

			.cont_side {
				width: 30.8%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.cont_side_top {
					height: 50%;

					.cont {
						height: calc(100% - 44px);
						box-sizing: border-box;
						padding: 30px 40px;
						display: flex;
						align-items: center;
						justify-content: space-between;

						.community {
							width: 40%;

							ul {
								li {
									display: flex;
									justify-content: space-between;
									align-items: center;
									border-bottom: 1px solid #3a445a;
									padding: 16px 5px;
									font-size: 16px;

									&:last-child {
										border-bottom: 0;
									}

									>div {
										padding-left: 10px;
										border-left: 3px solid #153560;

										&.blue {
											border-color: #5c7ceb;
										}

										&.yellow {
											border-color: #fdad3e;
										}

										&.lightblue {
											border-color: #46cad3;
										}

										&.puple {
											border-color: #aa89bd;
										}
									}

									span {
										display: flex;
										align-items: center;

										em {
											font-size: 22px;
											font-family: Arial, Helvetica, sans-serif;
											margin-right: 4px;
											font-weight: bold;
										}
									}
								}
							}
						}

						.echart {
							width: 52%;
							font-size: 16px;
							height: 100%;
						}
					}

					.coincont {
						height: calc(100% - 44px);

						.coin {
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding: 20px 40px;
							height: 136px;
							box-sizing: border-box;

							.coinleft {
								font-size: 16px;
								padding-left: 60px;
								background-image: url('../../assets/imgs/monitor/monitor_jifen.png');
								background-size: 46px auto;
								background-repeat: no-repeat;
								background-position: 5px center;

								span {
									display: block;
									font-weight: bold;
									font-family: Arial, Helvetica, sans-serif;
									font-size: 26px;
								}
							}

							.coincent {
								font-size: 16px;

								>div {
									display: flex;
									align-items: center;
								}

								span {
									font-family: Arial, Helvetica, sans-serif;
									font-weight: bold;
									font-size: 22px;
									margin-left: 10px;
								}
							}

							.coinright {
								display: flex;
								align-items: center;

								.chart {
									width: 96px;
									height: 96px;
									margin-left: 10px;

									::v-deep .el-progress--circle .el-progress__text {
										color: #fff;
									}

									::v-deep .el-bg-inner-running {
										stroke: rgba(0, 122, 255, 0.3);
									}
								}
							}

							.line {
								height: 66px;
								width: 3px;
								background-color: #2a364f;
							}
						}

						.coindispense {
							padding: 10px 40px 20px;
							height: calc(100% - 136px);
							box-sizing: border-box;

							.text {
								line-height: 24px;
								font-size: 14px;
								font-weight: bold;
								padding-left: 40px;
							}

							.chart {
								height: calc(100% - 24px);
							}
						}
					}
				}

				.cont_side_bot {
					height: 47%;

					.cont {
						height: calc(100% - 44px);
						box-sizing: border-box;
						padding: 30px 40px;
						padding-right: 10px;
						display: flex;
						align-items: center;
						justify-content: space-between;

						.activitytype {
							width: 38%;

							>div {
								font-size: 18px;
								display: flex;
								align-items: center;
								padding-left: 30px;
								margin-bottom: 25px;

								span {
									font-size: 16px;
									color: #00d7d4;
									display: flex;
									align-items: center;
									margin-left: 15px;

									em {
										font-weight: bold;
										font-size: 24px;
										margin-right: 3px;
									}
								}
							}

							ul {
								li {
									display: flex;
									justify-content: space-between;
									align-items: center;
									border: 1px solid #057a93;
									padding: 14px 24px 14px 16px;
									margin-bottom: 13px;
									font-size: 16px;
									border-radius: 12px;
									position: relative;

									&::before,
									&::after {
										position: absolute;
										content: "";
										width: 1px;
										height: 30px;
										background-color: #06142f;
										top: 50%;
										margin-top: -15px;
									}

									&::before {
										left: -1px;
									}

									&::after {
										right: -1px;
									}

									>div {
										display: flex;
										align-items: center;

										i {
											font-size: 26px;
											color: #00d7d4;
											margin-right: 15px;
										}
									}

									span {
										display: flex;
										align-items: center;

										em {
											font-size: 22px;
											font-family: Arial, Helvetica, sans-serif;
											margin-right: 4px;
											font-weight: bold;
											color: #00d7d4;
										}
									}
								}
							}
						}

						.echart {
							width: 52%;
							font-size: 16px;
							height: 100%;
						}
					}

					.exchangecont {
						height: calc(100% - 44px);

						.exchange_top {
							height: 90px;

							ul {
								height: 100%;
								display: flex;
								align-items: center;
								justify-content: space-between;
								padding: 0 70px 0 40px;

								li {
									line-height: 1.6em;

									>div {
										display: flex;
									}

									em,
									span {
										font-weight: bold;
										font-size: 20px;
										font-family: Arial, Helvetica, sans-serif;
										margin-right: 3px;
									}

									span {
										margin-left: 10px;
									}
								}
							}
						}

						.exchange_bot {
							height: calc(100% - 90px);
							padding: 10px 20px 30px;
							box-sizing: border-box;
							display: flex;
							justify-content: space-between;

							.text {
								line-height: 24px;
								font-size: 16px;
								font-weight: bold;

							}

							.chart {
								height: calc(100% - 24px);
							}

							.exchange_bot_left {
								width: 35%;
								height: 100%;

								.text {
									padding-left: 20px;
								}
							}

							.exchange_bot_right {
								width: 62%;
								height: 100%;
							}
						}
					}
				}
			}

			.cont_cent {
				width: 36%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.cont_cent_top {
					height: 53%;

					.echart {
						height: calc(100% - 44px);
						padding: 0 40px;

						.gifpart {
							overflow: hidden;
							border-radius: 6px;
							height: 100%;
							position: relative;
							display: flex;
							justify-content: center;
							align-items: center;
							img {
								width: auto;
								height: 100%;
							}

							em {
								display: inline-block;
								animation-duration: 1s;
							}

							>div {
								position: absolute;
								padding: 6px 12px;
								background-color: #1e5ab0;
								border-radius: 6px;
								line-height: 1.6em;
								z-index: 10;
								cursor: pointer;
								box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1), 0px 0px 10px 2px rgba(0, 0, 0, 0.2) inset;
								transition: 0.3s all;
								&:hover{
									transform: scale(1.2);
								}
								&.one {
									left: 24.4%;
									top: 13.4%;
								}

								&.two {
									left: 41.4%;
									top: 13.4%;
								}

								&.three {
									left: 32.9%;
									top: 30.9%;

									span {
										color: #ffcf3c;
									}
								}

								&.four {
									left: 53.1%;
									top: 27.5%;

									span {
										color: #39f3ff;
									}
								}

								&.five {
									left: 12%;
									top: 41%;
								}

								&.six {
									left: 63.8%;
									top: 44%;

									span {
										color: #ff8c8c;
									}
								}

								&.one,
								&.two {
									span {
										margin-left: 3px;
										color: #ffcf3c;
									}
								}

								&.five {
									span {
										color: #37ebf9;

										em {
											font-weight: bold;
										}
									}
								}

								&.three,
								&.four,
								&.six {
									span {
										display: flex;
										align-items: center;

										em {
											font-size: 21px;
											font-weight: bold;
											margin-right: 3px;
										}
									}
								}
							}

						}
					}
				}

				.cont_cent_bot {
					height: 43%;
					padding: 0 40px 30px;
					box-sizing: border-box;

					.toprank {
						font-size: 16px;
						line-height: 32px;
						font-size: 20px;
					}

					.echart {
						height: calc(100% - 32px);
					}
				}
			}
		}
	}
</style>
